@use "../profitmark-material/variables";

.p-tabview {
    .p-tabview-nav {
        position: relative;

        li {
            .p-tabview-nav-link {
                transition: background-color variables.$transitionDuration;
                border-radius: 0;
                
                > .p-ink {
                    background-color: rgba(variables.$primaryColor, .16);
                }

                &:focus {
                    background-color: rgba(variables.$primaryColor, .12);
                }
            }
        }

        .p-tabview-ink-bar {
            z-index: 1;
            display: block;
            position: absolute;
            bottom: 0;
            height: 2px;
            background-color: variables.$primaryColor;
            transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
        }
    }
}