@use "../../../profitmark-material/variables";
@use "../../mixins";

.p-fileupload {
    .p-fileupload-buttonbar {
        background: variables.$panelHeaderBg;
        padding: variables.$panelHeaderPadding;
        border: variables.$panelHeaderBorder;
        color: variables.$panelHeaderTextColor;
        border-bottom: 0 none;
        border-top-right-radius: variables.$borderRadius;
        border-top-left-radius: variables.$borderRadius;

        .p-button {
            margin-right: variables.$inlineSpacing;

            &.p-fileupload-choose.p-focus {
                @include mixins.focused();
            }
        }
    }

    .p-fileupload-content {
        background: variables.$panelContentBg;
        padding: variables.$fileUploadContentPadding;
        border: variables.$panelContentBorder;
        color: variables.$panelContentTextColor;
        border-bottom-right-radius: variables.$borderRadius;
        border-bottom-left-radius: variables.$borderRadius;

        &.p-fileupload-highlight {
            border-color: variables.$fileUploadContentHoverBorder;
            border-style: dashed;
            background-color: variables.$highlightBg;
        }
    }

    .p-progressbar {
        height: variables.$fileUploadProgressBarHeight;
    }

    .p-fileupload-row {
        > div {
            padding: variables.$tableBodyCellPadding;
        }
    }

    &.p-fileupload-advanced {
        .p-message {
            margin-top: 0;
        }
    }
}

.p-fileupload-choose {
    &:not(.p-disabled):hover {
        background: variables.$buttonHoverBg;
        color: variables.$buttonTextHoverColor;
        border-color: variables.$buttonHoverBorderColor;
    }

    &:not(.p-disabled):active {
        background: variables.$buttonActiveBg;
        color: variables.$buttonTextActiveColor;
        border-color: variables.$buttonActiveBorderColor;
    }
}
