@use "../profitmark-material/variables";

.p-carousel {
    .p-carousel-indicators {

        .p-carousel-indicator {
            &.p-highlight {
                button {
                    background: variables.$accentColor;
                    color: variables.$accentTextColor;
                }
            }
        }
    }
}
