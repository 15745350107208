@use "../profitmark-material/variables";

.p-selectbutton {
    .p-button {
        &:focus {
            background: variables.$toggleButtonActiveBg;
            border-color: variables.$toggleButtonActiveBorderColor;
    
            &.p-highlight {
                background: variables.$toggleButtonActiveHoverBg;
                border-color: variables.$toggleButtonActiveHoverBorderColor;
            }
        }
    }
}