@use "../../../profitmark-material/variables";
@use "../../mixins";

@use 'sass:math';

.p-rating {
    gap: variables.$inlineSpacing;

    .p-rating-item {
        border-radius: 50%;
        outline-color: transparent;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        .p-rating-icon {
            color: variables.$ratingStarIconOffColor;
            transition: variables.$formElementTransition;   
            font-size: variables.$ratingIconFontSize;

            &.p-icon {
                width: variables.$ratingIconFontSize;
                height: variables.$ratingIconFontSize;
            }
    
            &.p-rating-cancel {
                color: variables.$ratingCancelIconColor;
            }
        }

        &.p-focus {
            @include mixins.focused();
        }

        &.p-rating-item-active {
            .p-rating-icon {
                color: variables.$ratingStarIconOnColor;
            }
        }
    }

    &:not(.p-disabled):not(.p-readonly) {
        .p-rating-item {
            &:hover {
                .p-rating-icon {
                    color: variables.$ratingStarIconHoverColor;

                    &.p-rating-cancel {
                        color: variables.$ratingCancelIconHoverColor;
                    }
                }
            }
        }
    }
}

@if (variables.$highlightBg == variables.$ratingStarIconOnColor) {
    .p-highlight {
        .p-rating {
            .p-rating-item {
                &.p-rating-item-active {
                    .p-rating-icon {
                        color: variables.$highlightTextColor;
                    }
                }
            }
        }
    }
}
