@use "sass:meta";
/* You can add global styles to this file, and also import other style files */
@import 'primeflex/primeflex.css';
// @import './flags.scss';
@import "flag-icons/css/flag-icons.min.css";

@include meta.load-css('profitmark-material/theme.scss');
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';

@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";
