@use "../profitmark-material/variables";

.p-inputswitch {
    .p-inputswitch-slider:before {
        transition-property: box-shadow transform;
        box-shadow: 0px 3px 1px -2px rgba(variables.$overlayColor, 0.2), 0px 2px 2px 0px rgba(variables.$overlayColor, 0.14), 0px 1px 5px 0px rgba(variables.$overlayColor,.12);
    }

    &:not(.p-disabled):hover {
        .p-inputswitch-slider:before {
            box-shadow: 0px 3px 1px -2px rgba(variables.$overlayColor, 0.2), 0px 2px 2px 0px rgba(variables.$overlayColor, 0.14), 0px 1px 5px 0px rgba(variables.$overlayColor,.12), 0 0 1px 10px rgba(variables.$overlayColor, .04),
        }
    }

    &.p-focus,
    &.p-focus:not(.p-disabled):hover  {
        .p-inputswitch-slider:before {
            box-shadow: 0 0 1px 10px rgba(variables.$overlayColor, .12), 0px 3px 1px -2px rgba(variables.$overlayColor, 0.2), 0px 2px 2px 0px rgba(variables.$overlayColor, 0.14), 0px 1px 5px 0px rgba(variables.$overlayColor,.12);
        }
    }

    &.p-inputswitch-checked:not(.p-disabled):hover {
        .p-inputswitch-slider:before {
            box-shadow: 0 0 1px 10px rgba(variables.$inputSwitchHandleOnBg, .04), 0px 3px 1px -2px rgba(variables.$overlayColor, 0.2), 0px 2px 2px 0px rgba(variables.$overlayColor, 0.14), 0px 1px 5px 0px rgba(variables.$overlayColor,.12);
        }
    }

    &.p-inputswitch-checked.p-focus,
    &.p-inputswitch-checked.p-focus:not(.p-disabled):hover {
        .p-inputswitch-slider:before {
            box-shadow: 0 0 1px 10px rgba(variables.$inputSwitchHandleOnBg, .12), 0px 3px 1px -2px rgba(variables.$overlayColor, 0.2), 0px 2px 2px 0px rgba(variables.$overlayColor, 0.14), 0px 1px 5px 0px rgba(variables.$overlayColor,.12);
        }
    }
}
