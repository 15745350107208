@use "../../../profitmark-material/variables";
@use "../../mixins";

.p-column-filter-row {
    .p-column-filter-menu-button,
    .p-column-filter-clear-button {
        margin-left: variables.$inlineSpacing;
    }
}

.p-column-filter-menu-button {
    width: variables.$actionIconWidth;
    height: variables.$actionIconHeight;
    color: variables.$actionIconColor;
    border: variables.$actionIconBorder;
    background: variables.$actionIconBg;
    border-radius: variables.$actionIconBorderRadius;
    transition: variables.$actionIconTransition;

    &:hover {
        color: variables.$actionIconHoverColor;
        border-color: variables.$actionIconHoverBorderColor;
        background: variables.$actionIconHoverBg;
    }

    &.p-column-filter-menu-button-open,
    &.p-column-filter-menu-button-open:hover {
        background: variables.$actionIconHoverBg;
        color: variables.$actionIconHoverColor;
    }

    &.p-column-filter-menu-button-active,
    &.p-column-filter-menu-button-active:hover {
        background: variables.$highlightBg;
        color: variables.$highlightTextColor;
    }

    &:focus-visible {
        @include mixins.focused();
    }

}

.p-column-filter-clear-button {
    width: variables.$actionIconWidth;
    height: variables.$actionIconHeight;
    color: variables.$actionIconColor;
    border: variables.$actionIconBorder;
    background: variables.$actionIconBg;
    border-radius: variables.$actionIconBorderRadius;
    transition: variables.$actionIconTransition;

    &:hover {
        color: variables.$actionIconHoverColor;
        border-color: variables.$actionIconHoverBorderColor;
        background: variables.$actionIconHoverBg;
    }

    &:focus-visible {
        @include mixins.focused();
    }
}

.p-column-filter-overlay {
    background: variables.$inputOverlayBg;
    color: variables.$inputListTextColor;
    border: variables.$inputOverlayBorder;
    border-radius: variables.$borderRadius;
    box-shadow: variables.$inputOverlayShadow;
    min-width: variables.$menuWidth;

    .p-column-filter-row-items {
        padding: variables.$inputListPadding;

        .p-column-filter-row-item {
            margin: variables.$inputListItemMargin;
            padding: variables.$inputListItemPadding;
            border: variables.$inputListItemBorder;
            color: variables.$inputListItemTextColor;
            background: variables.$inputListItemBg;
            transition: variables.$listItemTransition;
            border-radius: variables.$inputListItemBorderRadius;

            &:first-child {
                margin-top: 0;
            }

            &.p-highlight {
                color: variables.$highlightTextColor;
                background: variables.$highlightBg;
            }

            &:not(.p-highlight):not(.p-disabled):hover {
                color: variables.$inputListItemTextHoverColor;
                background: variables.$inputListItemHoverBg;
            }

            &:focus-visible {
                @include mixins.focused-listitem();
            }
        }

        .p-column-filter-separator {
            border-top: variables.$divider;
            margin: variables.$menuSeparatorMargin;
        }
    }
}

.p-column-filter-overlay-menu {
    .p-column-filter-operator {
        padding: variables.$inputListHeaderPadding;
        border-bottom: variables.$inputListHeaderBorder;
        color: variables.$inputListHeaderTextColor;
        background: variables.$inputOverlayHeaderBg;
        margin: variables.$inputListHeaderMargin;
        border-top-right-radius: variables.$borderRadius;
        border-top-left-radius: variables.$borderRadius;
    }

    .p-column-filter-constraint {
        padding: variables.$panelContentPadding;
        border-bottom: variables.$divider;

        .p-column-filter-matchmode-dropdown {
            margin-bottom: variables.$inlineSpacing;
        }

        .p-column-filter-remove-button {
            margin-top: variables.$inlineSpacing;
        }

        &:last-child {
            border-bottom: 0 none;
        }
    }

    .p-column-filter-add-rule {
        padding: variables.$panelFooterPadding;
    }

    .p-column-filter-buttonbar {
        padding: variables.$panelContentPadding;
    } 
}