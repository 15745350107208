@use "sass:list";
@use "../../../profitmark-material/variables";
@use "../../mixins";

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
    @include mixins.invalid-input();
}

p-inputnumber.p-inputnumber-clearable {
    .p-inputnumber-input {
        padding-right: list.nth(variables.$inputPadding, 2) * 2 + variables.$primeIconFontSize;
    }
    
    .p-inputnumber-clear-icon {
        color: variables.$inputIconColor;
        right: list.nth(variables.$inputPadding, 2);
    }
}

p-inputnumber.p-inputnumber-clearable {
    .p-inputnumber-buttons-stacked{
        .p-inputnumber-clear-icon {
           right: variables.$buttonIconOnlyWidth + list.nth(variables.$inputPadding, 2);
        }
    }
    
    .p-inputnumber-buttons-horizontal{
        .p-inputnumber-clear-icon {
           right: variables.$buttonIconOnlyWidth + list.nth(variables.$inputPadding, 2);
        }
    }
}

p-inputnumber.p-inputnumber {
    &.p-variant-filled > .p-inputnumber-input {
        background-color: variables.$inputFilledBg;

        &:enabled:hover {
            background-color: variables.$inputFilledHoverBg;
        }

        &:enabled:focus {
            background-color: variables.$inputFilledFocusBg;
        }
    }
}