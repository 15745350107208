@use "../profitmark-material/variables";

.p-rating {
    gap: 0;

    .p-rating-item {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        align-items: center;
        display: inline-flex;
        justify-content: center;

        &.p-focus {
            background: rgba(variables.$primaryColor, .12);

            &.p-rating-cancel-item {
                background: rgba(variables.$ratingCancelIconColor, .12);
            }
        }
    }

    &:not(.p-disabled):not(.p-readonly) {
        .p-rating-item {
            &:hover {
                background-color: rgba(0,0,0,.04);

                &.p-rating-cancel-item {
                    background: rgba(variables.$ratingCancelIconColor, .04);
                }
            }
        }
    }
}