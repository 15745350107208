@use "../profitmark-material/variables";

.p-tree {
    .p-tree-container {
        .p-treenode {
            outline: 0 none;
            
            &:focus > .p-treenode-content {
                background: variables.$emphasis-lower;

                &.p-highlight {
                    background: rgba(variables.$primaryColor, .24);
                }
            }
        }
    }
}
