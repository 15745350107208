@use "../../../profitmark-material/variables";

.p-inline-message {
    padding: variables.$inlineMessagePadding;
    margin: variables.$inlineMessageMargin;
    border-radius: variables.$borderRadius;

    &.p-inline-message-info {
        background: variables.$infoMessageBg;
        border: variables.$infoMessageBorder;
        border-width: variables.$inlineMessageBorderWidth;
        color: variables.$infoMessageTextColor;

        .p-inline-message-icon {
            color: variables.$infoMessageIconColor;
        }
    }

    &.p-inline-message-success {
        background: variables.$successMessageBg;
        border: variables.$successMessageBorder;
        border-width: variables.$inlineMessageBorderWidth;
        color: variables.$successMessageTextColor;

        .p-inline-message-icon {
            color: variables.$successMessageIconColor;
        }
    }

    &.p-inline-message-warn {
        background: variables.$warningMessageBg;
        border: variables.$warningMessageBorder;
        border-width: variables.$inlineMessageBorderWidth;
        color: variables.$warningMessageTextColor;

        .p-inline-message-icon {
            color: variables.$warningMessageIconColor;
        }
    }

    &.p-inline-message-error {
        background: variables.$errorMessageBg;
        border: variables.$errorMessageBorder;
        border-width: variables.$inlineMessageBorderWidth;
        color: variables.$errorMessageTextColor;

        .p-inline-message-icon {
            color: variables.$errorMessageIconColor;
        }
    }

    .p-inline-message-icon {
        font-size: variables.$inlineMessageIconFontSize;
        margin-right: variables.$inlineSpacing;
    }

    .p-icon {
        width: variables.$inlineMessageIconFontSize;
        height: variables.$inlineMessageIconFontSize;
    }

    .p-inline-message-text {
        font-size: variables.$inlineMessageTextFontSize;
    }

    &.p-inline-message-icon-only {
        .p-inline-message-icon {
            margin-right: 0;
        }
    }
}
