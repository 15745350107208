@use "../profitmark-material/variables";

.p-panel {
    border-radius: variables.$borderRadius;
    box-shadow: variables.$cardShadow;

    .p-panel-header,
    .p-panel-content,
    .p-panel-footer {
        border: 0 none;
    }

    .p-panel-content {
        padding-top: 0;
    }

    .p-panel-title {
        font-size: 1.25rem;
    }

    .p-panel-header-icon {
        &:focus {
            background: variables.$emphasis-lower;
        }
    }
}
