@use "../profitmark-material/variables";

.p-splitbutton {
    &.p-button-text,
    &.p-button-outlined {
        > .p-button {
            &:not(:disabled):focus {
                background: rgba(variables.$buttonBg, 0.12);
            }

            &:not(:disabled):active {
                background: rgba(variables.$buttonBg, 0.16);
            }
        }
    }

    &.p-button-outlined {
        > .p-button {
            box-shadow: inset 0 0 0 1px;

            &:enabled:focus {
                box-shadow: inset 0 0 0 1px;
            }

            &:enabled:active {
                box-shadow: inset 0 0 0 1px;
            }

            &.p-splitbutton-menubutton {
                margin-left: -1px;
                width: calc(#{variables.$buttonIconOnlyWidth} + 1px);
            }
        }
    }

    &.p-disabled {
        &.p-button-text {
            > .p-button {
                background-color: transparent !important;
                color: variables.$emphasis-low !important;
            }
        }

        &.p-button-outlined {
            > .p-button {
                background-color: transparent !important;
                color: variables.$emphasis-low !important;
                border-color: variables.$emphasis-low !important;
            }
        }
    }

    &.p-button-raised {
        > .p-button {
            &:not(:disabled):focus {
                box-shadow: variables.$raisedButtonShadow;
            }
        }
    }
}

.p-splitbutton.p-button-secondary {
    &.p-button-text,
    &.p-button-outlined {
        > .p-button {
            &:not(:disabled):focus {
                background: rgba(variables.$secondaryButtonBg, 0.12);
            }

            &:not(:disabled):active {
                background: rgba(variables.$secondaryButtonBg, 0.16);
            }
        }
    }
}

.p-splitbutton.p-button-info > .p-button {
    &.p-button-text,
    &.p-button-outlined {
        > .p-button {
            &:not(:disabled):focus {
                background: rgba(variables.$infoButtonBg, 0.12);
            }

            &:not(:disabled):active {
                background: rgba(variables.$infoButtonBg, 0.16);
            }
        }
    }
}

.p-splitbutton.p-button-success {
    &.p-button-text,
    &.p-button-outlined {
        > .p-button {
            &:not(:disabled):focus {
                background: rgba(variables.$successButtonBg, 0.12);
            }

            &:not(:disabled):active {
                background: rgba(variables.$successButtonBg, 0.16);
            }
        }
    }
}

.p-splitbutton.p-button-warning {
    &.p-button-text,
    &.p-button-outlined {
        > .p-button {
            &:not(:disabled):focus {
                background: rgba(variables.$warningButtonBg, 0.12);
            }

            &:not(:disabled):active {
                background: rgba(variables.$warningButtonBg, 0.16);
            }
        }
    }
}

.p-splitbutton.p-button-help {
    &.p-button-text,
    &.p-button-outlined {
        > .p-button {
            &:not(:disabled):focus {
                background: rgba(variables.$helpButtonBg, 0.12);
            }

            &:not(:disabled):active {
                background: rgba(variables.$helpButtonBg, 0.16);
            }
        }
    }
}

.p-splitbutton.p-button-danger {
    &.p-button-text,
    &.p-button-outlined {
        > .p-button {
            &:not(:disabled):focus {
                background: rgba(variables.$dangerButtonBg, 0.12);
            }

            &:not(:disabled):active {
                background: rgba(variables.$dangerButtonBg, 0.16);
            }
        }
    }
}
