@use "../../../profitmark-material/variables";

.p-splitbutton {
    border-radius: variables.$borderRadius;

    &.p-button-rounded {
        border-radius: variables.$roundedButtonBorderRadius;

        > .p-button {
            border-radius: variables.$roundedButtonBorderRadius;
        }
    }

    &.p-button-raised {
        box-shadow: variables.$raisedButtonShadow;
    }
}
