@use "../profitmark-material/variables";

.p-galleria {
    .p-galleria-indicators {
        padding: variables.$galleriaIndicatorsPadding;

        .p-galleria-indicator {
            &.p-highlight {
                button {
                    background: variables.$accentColor;
                    color: variables.$accentTextColor;
                }
            }
        }
    }

    &.p-galleria-indicator-onitem {
        .p-galleria-indicators {
            .p-galleria-indicator {
                &.p-highlight {
                    button {
                        background: rgba(variables.$accentColor, .68);
                        color: variables.$accentTextColor;
                    }
                }
            }
        }
    }
}