@use "../../../profitmark-material/variables";
@use "../../mixins";

.p-megamenu {
    padding: variables.$horizontalMenuPadding;
    background: variables.$horizontalMenuBg;
    color: variables.$horizontalMenuTextColor;
    border: variables.$horizontalMenuBorder;
    border-radius: variables.$borderRadius;

    .p-megamenu-root-list {  
        outline: 0 none;
    }

    .p-menuitem {
        @include mixins.menuitem();
    }

    .p-megamenu-panel {
        background: variables.$overlayMenuBg;
        color: variables.$menuTextColor;
        border: variables.$overlayMenuBorder;
        box-shadow: variables.$overlayMenuShadow;
        border-radius: variables.$borderRadius;
    }

    .p-submenu-header {
        margin: variables.$submenuHeaderMargin;
        padding: variables.$submenuHeaderPadding;
        color: variables.$submenuHeaderTextColor;
        background: variables.$submenuHeaderBg;
        font-weight: variables.$submenuHeaderFontWeight;
        border-top-right-radius: variables.$borderRadius; 
        border-top-left-radius: variables.$borderRadius; 
    }

    .p-submenu-list {
        padding: variables.$verticalMenuPadding;
        width: variables.$menuWidth;

        .p-menuitem-separator {
            border-top: variables.$divider;
            margin: variables.$menuSeparatorMargin;
        }
    }

    &.p-megamenu-vertical {
        width: variables.$menuWidth;
        padding: variables.$verticalMenuPadding;
    }

    &.p-megamenu-horizontal {
        .p-megamenu-root-list {  
            > .p-menuitem {
                @include mixins.horizontal-rootmenuitem();
            }
        }
    }
}