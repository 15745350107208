@use "sass:list";
@use "../../../profitmark-material/variables";
@use "../../mixins";

@use 'sass:math';

.p-chips {
    &:not(.p-disabled):hover {
        .p-chips-multiple-container {
            border-color: variables.$inputHoverBorderColor;
        }
    }

    &:not(.p-disabled).p-focus {
        .p-chips-multiple-container {
            @include mixins.focused-input();
        }
    }

    .p-chips-multiple-container {
        padding: math.div(list.nth(variables.$inputPadding, 1), 2) list.nth(variables.$inputPadding, 2);
        gap: variables.$inlineSpacing;

        .p-chips-token {
            padding: math.div(list.nth(variables.$inputPadding, 1), 2) list.nth(variables.$inputPadding, 2);
            margin-right: variables.$inlineSpacing;
            background: variables.$chipBg;
            color: variables.$chipTextColor;
            border-radius: variables.$chipBorderRadius;

            &.p-focus {
                background: variables.$chipFocusBg;
                color: variables.$chipFocusTextColor;
            }

            .p-chips-token-icon {
                margin-left: variables.$inlineSpacing;
            }
        }

        .p-chips-input-token {
            padding: math.div(list.nth(variables.$inputPadding, 1), 2) 0;
                
            input {
                font-family: var(--font-family);
                font-feature-settings: var(--font-feature-settings, normal);
                font-size: variables.$inputTextFontSize;
                color: variables.$textColor;
                padding: 0;
                margin: 0;
            }
        }
    }
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
    @include mixins.invalid-input();
}

p-chips.p-chips-clearable {
    .p-inputtext {
        padding-right: list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize;
    }

    .p-chips-clear-icon {
        color: variables.$inputIconColor;
        right: list.nth(variables.$inputPadding, 2);
    } 
}