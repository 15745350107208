@use "../profitmark-material/variables";

.p-orderlist {
    .p-orderlist-list {
        .p-orderlist-item {
            &:focus {
                background: variables.$emphasis-lower;

                &.p-highlight {
                    background: rgba(variables.$primaryColor, .24);
                }
            }
        }
    }
}
