@use "sass:list";
@use "../../../profitmark-material/variables";
@use "../../mixins";

@use 'sass:math';

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
    @include mixins.invalid-input();
}

p-inputmask.p-inputmask-clearable {
    .p-inputtext {
        padding-right: list.nth(variables.$inputPadding, 2) * 2 + variables.$primeIconFontSize;
    }
    
    .p-inputmask-clear-icon {
        color: variables.$inputIconColor;
        right: list.nth(variables.$inputPadding, 2);
    }
}

.p-inputmask {
    &.p-variant-filled {
        background-color: variables.$inputFilledBg;

        &:enabled:hover {
            background-color: variables.$inputFilledHoverBg;
        }

        &:enabled:focus {
            background-color: variables.$inputFilledFocusBg;
        }
    }
}