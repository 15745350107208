@use "sass:list";
@use "../../../profitmark-material/variables";
@use "../../mixins";

@use 'sass:math';

.p-autocomplete {
    .p-autocomplete-loader {
        right: list.nth(variables.$inputPadding, 2); 
    }

    &.p-autocomplete-dd {
        .p-autocomplete-loader {
            right: list.nth(variables.$inputPadding, 2) + variables.$buttonIconOnlyWidth;
        }
    }

    &:not(.p-disabled):hover {
        .p-autocomplete-multiple-container {
            border-color: variables.$inputHoverBorderColor;
        }
    }

    &:not(.p-disabled).p-focus {
        .p-autocomplete-multiple-container {
            @include mixins.focused-input();
        }
    }

    .p-autocomplete-multiple-container {
        padding: math.div(list.nth(variables.$inputPadding, 1), 2) list.nth(variables.$inputPadding, 2);
        gap: variables.$inlineSpacing;

        .p-autocomplete-input-token {
            padding: math.div(list.nth(variables.$inputPadding, 1), 2) 0;
            
            input {
                font-family: var(--font-family);
                font-feature-settings: var(--font-feature-settings, normal);
                font-size: variables.$inputTextFontSize;
                color: variables.$textColor;
                padding: 0;
                margin: 0;
            }
        }

        .p-autocomplete-token {
            padding: math.div(list.nth(variables.$inputPadding, 1), 2) list.nth(variables.$inputPadding, 2);
            background: variables.$chipBg;
            color: variables.$chipTextColor;
            border-radius: variables.$chipBorderRadius;

            .p-autocomplete-token-icon {
                margin-left: variables.$inlineSpacing;
            }

            &.p-focus {
                background: variables.$chipFocusBg;
                color: variables.$chipFocusTextColor;
            }
        }
    }

    &.p-invalid.p-component > .p-inputtext {
        @include mixins.invalid-input();
    }
}

.p-autocomplete-panel {
    background: variables.$inputOverlayBg;
    color: variables.$inputListTextColor;
    border: variables.$inputOverlayBorder;
    border-radius: variables.$borderRadius;
    box-shadow: variables.$inputOverlayShadow;

    .p-autocomplete-items {
        padding: variables.$inputListPadding;

        .p-autocomplete-item {
            margin: variables.$inputListItemMargin;
            padding: variables.$inputListItemPadding;
            border: variables.$inputListItemBorder;
            color: variables.$inputListItemTextColor;
            background: variables.$inputListItemBg;
            transition: variables.$listItemTransition;
            border-radius: variables.$inputListItemBorderRadius;

            &:first-child {
                margin-top: 0;
            }

            &.p-highlight {
                color: variables.$highlightTextColor;
                background: variables.$highlightBg;

                &.p-focus {
                    background: variables.$highlightFocusBg;
                }
            }

            &:not(.p-highlight):not(.p-disabled) {
                &.p-focus {
                    color: variables.$inputListItemTextFocusColor;
                    background: variables.$inputListItemFocusBg;
                }

                &:hover {
                    color: variables.$inputListItemTextHoverColor;
                    background: variables.$inputListItemHoverBg;
                }
            }
        }

        .p-autocomplete-item-group {
            margin: variables.$submenuHeaderMargin;
            padding: variables.$submenuHeaderPadding;
            color: variables.$submenuHeaderTextColor;
            background: variables.$submenuHeaderBg;
            font-weight: variables.$submenuHeaderFontWeight;
        }

        .p-autocomplete-empty-message {
            padding: variables.$inputListItemPadding;
            color: variables.$inputListItemTextColor;
            background: variables.$inputListItemBg;
        }
    }
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    @include mixins.invalid-input();
}

p-autocomplete.p-autocomplete-clearable {
    .p-inputtext {
        padding-right: list.nth(variables.$inputPadding, 2) * 2 + variables.$primeIconFontSize;
    }

    .p-autocomplete-clear-icon {
        color: variables.$inputIconColor;
        right: list.nth(variables.$inputPadding, 2);
    }
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd {
    .p-autocomplete-clear-icon {
        color: variables.$inputIconColor;
        right: variables.$buttonIconOnlyWidth + list.nth(variables.$inputPadding, 2);
    }
}
