@use "sass:meta";
@use '_mixins';

@layer primeng {
    @include meta.load-css('_accordion');
    @include meta.load-css('_autocomplete');
    @include meta.load-css('_breadcrumb');
    @include meta.load-css('_button');
    @include meta.load-css('_calendar');
    @include meta.load-css('_cascadeselect');
    @include meta.load-css('_carousel');
    @include meta.load-css('_checkbox');
    @include meta.load-css('_chips');
    @include meta.load-css('_datatable');
    @include meta.load-css('_dropdown');
    @include meta.load-css('_galleria');
    @include meta.load-css('_input');
    @include meta.load-css('_inputgroup');
    @include meta.load-css('_inputmask');
    @include meta.load-css('_inputnumber');
    @include meta.load-css('_inputswitch');
    @include meta.load-css('_fieldset');
    @include meta.load-css('_float_label');
    @include meta.load-css('_listbox');
    @include meta.load-css('_megamenu');
    @include meta.load-css('_menu');
    @include meta.load-css('_menubar');
    @include meta.load-css('_multiselect');
    @include meta.load-css('_orderlist');
    @include meta.load-css('_overlaypanel');
    @include meta.load-css('_paginator');
    @include meta.load-css('_panel');
    @include meta.load-css('_panelmenu');
    @include meta.load-css('_picklist');
    @include meta.load-css('_progressbar');
    @include meta.load-css('_radiobutton');
    @include meta.load-css('_rating');
    @include meta.load-css('_selectbutton');
    @include meta.load-css('_slidemenu');
    @include meta.load-css('_slider');
    @include meta.load-css('_steps');
    @include meta.load-css('_tabview');
    @include meta.load-css('_toolbar');
    @include meta.load-css('_tooltip');
    @include meta.load-css('_tieredmenu');
    @include meta.load-css('_treeselect');
    @include meta.load-css('_tree');
    @include meta.load-css('_treetable');
    @include meta.load-css('_tabmenu');
    @include meta.load-css('_timeline');
    @include meta.load-css('_togglebutton');
    @include meta.load-css('_splitbutton');
    @include meta.load-css('_scrollpanel');
    @include meta.load-css('_splitter');
}