@use "sass:color";
@use "sass:list";
@use "../../../profitmark-material/variables";

@use 'sass:math';

.p-overlaypanel {
    background: variables.$overlayContentBg;
    color: variables.$panelContentTextColor;
    border: variables.$overlayContentBorder;
    border-radius: variables.$borderRadius;
    box-shadow: variables.$overlayContainerShadow;

    .p-overlaypanel-content {
        padding: variables.$panelContentPadding;
    }

    .p-overlaypanel-close {
        background: variables.$buttonBg;
        color: variables.$buttonTextColor;
        width: variables.$actionIconWidth;
        height: variables.$actionIconHeight;
        transition: variables.$actionIconTransition;
        border-radius: variables.$actionIconBorderRadius;
        position: absolute;
        top: math.div(-1 * variables.$actionIconWidth, 2);
        right: math.div(-1 * variables.$actionIconWidth, 2);

        &:enabled:hover {
            background: variables.$buttonHoverBg;
            color: variables.$buttonTextHoverColor;
        }
    }

    &:after {
        border: solid transparent;
        border-color: rgba(variables.$overlayContentBg, 0);
        border-bottom-color: variables.$overlayContentBg;
    }

    &:before {
        border: solid transparent;

        @if (list.nth(variables.$overlayContentBorder, 2) == 'none') {
            border-color: rgba(variables.$overlayContentBg, 0);
            border-bottom-color: color.scale(variables.$overlayContentBg, $lightness: -5%);
        }
        @else {
            border-color: rgba(list.nth(variables.$overlayContentBorder, 3), 0);
            border-bottom-color: color.scale(list.nth(variables.$overlayContentBorder, 3), $lightness: -5%);
        }
    }

    &.p-overlaypanel-flipped {
        &:after {
            border-top-color: variables.$overlayContentBg;
        }

        &:before {
            @if (list.nth(variables.$overlayContentBorder, 2) == 'none') {
                border-top-color: variables.$overlayContentBg;
            }
            @else {
                border-top-color: list.nth(variables.$overlayContentBorder, 3);
            }
        }
    }
}
