@use "../../../profitmark-material/variables";
@use "../../mixins";

.p-breadcrumb {
    background: variables.$breadcrumbBg;
    border: variables.$breadcrumbBorder;
    border-radius: variables.$borderRadius;
    padding: variables.$breadcrumbPadding;

    .p-breadcrumb-list {
        li {
            .p-menuitem-link {
                transition: variables.$listItemTransition;
                border-radius: variables.$borderRadius;

                &:focus-visible {
                    @include mixins.focused();
                }

                .p-menuitem-text {
                    color: variables.$breadcrumbItemTextColor;
                }

                .p-menuitem-icon {
                    color: variables.$breadcrumbItemIconColor;
                }
            }

            &.p-menuitem-separator {
                margin: 0 variables.$inlineSpacing 0 variables.$inlineSpacing;
                color: variables.$breadcrumbSeparatorColor;
            }

            &:last-child {
                .p-menuitem-text {
                    color: variables.$breadcrumbLastItemTextColor;
                }

                .p-menuitem-icon {
                    color: variables.$breadcrumbLastItemIconColor;
                }
            }
        }
    }
}
