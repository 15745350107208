@use "../profitmark-material/variables";
@use "mixins";

p-inputmask.ng-dirty.ng-invalid {
    .p-inputtext {
        &:enabled:focus {
            box-shadow: inset 0 0 0 1px variables.$inputErrorBorderColor,inset 0 0 0 1px variables.$inputErrorBorderColor,inset 0 0 0 1px variables.$inputErrorBorderColor,inset 0 0 0 1px variables.$inputErrorBorderColor;
        }
    }
}

.p-input-filled {
    p-inputmask.ng-dirty.ng-invalid {
        .p-inputtext {
            @include mixins.invalid-filled-input();
        }
    }
}