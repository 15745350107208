@use "sass:list";
@use "../../../profitmark-material/variables";

.p-confirm-popup {
    background: variables.$overlayContentBg;
    color: variables.$panelContentTextColor;
    border: variables.$overlayContentBorder;
    border-radius: variables.$borderRadius;
    box-shadow: variables.$overlayContainerShadow;

    .p-confirm-popup-content {
        padding: variables.$panelContentPadding;
    }

    .p-confirm-popup-footer {
        text-align: right;
        padding: variables.$panelFooterPadding;

        button {
            margin: 0 variables.$inlineSpacing 0 0;
            width: auto;

            &:last-child {
                margin: 0;
            }
        }
    }

    &:after {
        border: solid transparent;
        border-color: rgba(variables.$overlayContentBg, 0);
        border-bottom-color: variables.$overlayContentBg;
    }

    &:before {
        border: solid transparent;

        @if (list.nth(variables.$overlayContentBorder, 2) == 'none') {
            border-color: rgba(variables.$overlayContentBg, 0);
            border-bottom-color: variables.$overlayContentBg;
        }
        @else {
            border-color: rgba(list.nth(variables.$overlayContentBorder, 3), 0);
            border-bottom-color: list.nth(variables.$overlayContentBorder, 3);
        }
    }

    &.p-confirm-popup-flipped {
        &:after {
            border-top-color: variables.$overlayContentBg;
        }

        &:before {
            @if (list.nth(variables.$overlayContentBorder, 2) == 'none') {
                border-top-color: variables.$overlayContentBg;
            }
            @else {
                border-top-color: list.nth(variables.$overlayContentBorder, 3);
            }
        }
    }

    .p-confirm-popup-icon {
        font-size: variables.$primeIconFontSize * 1.5;
        
        &.p-icon {
            width: variables.$primeIconFontSize * 1.5;
            height: variables.$primeIconFontSize * 1.5;
        }
    }

    .p-confirm-popup-message {
        margin-left: variables.$inlineSpacing * 2;
    }
}
