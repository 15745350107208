@use "../../../profitmark-material/variables";
@use "../../mixins";

.p-panelmenu {
    .p-panelmenu-header {
        outline: 0 none;

        .p-panelmenu-header-content {
            border: variables.$accordionHeaderBorder;
            color: variables.$accordionHeaderTextColor;
            background: variables.$accordionHeaderBg;
            border-radius: variables.$borderRadius;
            transition: variables.$listItemTransition;

            .p-panelmenu-header-action {
                color: variables.$accordionHeaderTextColor;
                padding: variables.$accordionHeaderPadding;
                font-weight: variables.$accordionHeaderFontWeight;

                .p-submenu-icon {
                    margin-right: variables.$inlineSpacing;
                }

                .p-menuitem-icon {
                    margin-right: variables.$inlineSpacing;
                }
            }
        }

        &:not(.p-disabled) {
            &:focus-visible {
                .p-panelmenu-header-content {
                    @include mixins.focused-inset();
                }
            }
        }

        &:not(.p-highlight):not(.p-disabled):hover {
            .p-panelmenu-header-content {
                background: variables.$accordionHeaderHoverBg;
                border-color: variables.$accordionHeaderHoverBorderColor;
                color: variables.$accordionHeaderTextHoverColor;
            }
        }

        &:not(.p-disabled).p-highlight {
            .p-panelmenu-header-content {
                background: variables.$accordionHeaderActiveBg;
                border-color: variables.$accordionHeaderActiveBorderColor;
                color: variables.$accordionHeaderTextActiveColor;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                margin-bottom: 0;
            }

            &:hover {
                .p-panelmenu-header-content {
                    border-color: variables.$accordionHeaderActiveHoverBorderColor;
                    background: variables.$accordionHeaderActiveHoverBg;
                    color: variables.$accordionHeaderTextActiveHoverColor;
                }
            }
        }
    }

    .p-panelmenu-content {
        padding: variables.$verticalMenuPadding;
        border: variables.$accordionContentBorder;
        background: variables.$accordionContentBg;
        color: variables.$accordionContentTextColor;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: variables.$borderRadius;
        border-bottom-left-radius: variables.$borderRadius;

        .p-panelmenu-root-list {
            outline: 0 none;
        }

        .p-menuitem {
            @include mixins.menuitem();

            .p-menuitem-content {
                .p-menuitem-link { 
                    .p-submenu-icon {
                        margin-right: variables.$inlineSpacing;
                    }
                }
            }
        }

        .p-menuitem-separator {
            border-top: variables.$divider;
            margin: variables.$menuSeparatorMargin;
        }

        .p-submenu-list:not(.p-panelmenu-root-list) {
            padding: variables.$treeNodeChildrenPadding;
        }
    }

    .p-panelmenu-panel {
        margin-bottom: variables.$accordionSpacing;

        @if variables.$accordionSpacing == 0 {
            .p-panelmenu-header {
                .p-panelmenu-header-content {
                    border-radius: 0;
                }
            }

            .p-panelmenu-content {
                border-radius: 0;
            }

            &:not(:first-child) {
                .p-panelmenu-header {
                    .p-panelmenu-header-content {
                        border-top: 0 none;
                    }

                    &:not(.p-highlight):not(.p-disabled):hover,
                    &:not(.p-disabled).p-highlight:hover {
                        .p-panelmenu-header-content {
                            border-top: 0 none;
                        }
                    }
                }
            }

            &:first-child {
                .p-panelmenu-header {
                    .p-panelmenu-header-content {
                        border-top-right-radius: variables.$borderRadius;
                        border-top-left-radius: variables.$borderRadius;
                    }
                }
            }

            &:last-child {
                .p-panelmenu-header:not(.p-highlight) {
                    .p-panelmenu-header-content {
                        border-bottom-right-radius: variables.$borderRadius;
                        border-bottom-left-radius: variables.$borderRadius;
                    }
                }

                .p-panelmenu-content {
                    border-bottom-right-radius: variables.$borderRadius;
                    border-bottom-left-radius: variables.$borderRadius;
                }
            }
        } 
    }
}
