@use "../../../profitmark-material/variables";
@use "../../mixins";

.p-treetable {
    .p-paginator-top {
        border-width: variables.$tableTopPaginatorBorderWidth;
        border-radius: 0;
    }

    .p-paginator-bottom {
        border-width: variables.$tableBottomPaginatorBorderWidth;
        border-radius: 0;
    }

    .p-treetable-header {
        background: variables.$tableHeaderBg;
        color: variables.$tableHeaderTextColor;
        border: variables.$tableHeaderBorder;
        border-width: variables.$tableHeaderBorderWidth;
        padding: variables.$tableHeaderPadding;
        font-weight: variables.$tableHeaderFontWeight;
    }

    .p-treetable-footer {
        background: variables.$tableFooterBg;
        color: variables.$tableFooterTextColor;
        border: variables.$tableFooterBorder;
        border-width: variables.$tableFooterBorderWidth;
        padding: variables.$tableFooterPadding;
        font-weight: variables.$tableFooterFontWeight;
    }

    .p-treetable-thead > tr > th {
        text-align: variables.$tableCellContentAlignment;
        padding: variables.$tableHeaderCellPadding;
        border: variables.$tableHeaderCellBorder;
        border-width: variables.$tableHeaderCellBorderWidth;
        font-weight: variables.$tableHeaderCellFontWeight;
        color: variables.$tableHeaderCellTextColor;
        background: variables.$tableHeaderCellBg;
        transition: variables.$listItemTransition;
    }

    .p-treetable-tfoot > tr > td {
        text-align: variables.$tableCellContentAlignment;
        padding: variables.$tableFooterCellPadding;
        border: variables.$tableFooterCellBorder;
        border-width: variables.$tableFooterCellBorderWidth;
        font-weight: variables.$tableFooterCellFontWeight;
        color: variables.$tableFooterCellTextColor;
        background: variables.$tableFooterCellBg;
    }

    .p-sortable-column {
        outline-color: variables.$focusOutlineColor;

        .p-sortable-column-icon {
            color: variables.$tableHeaderCellIconColor;
            margin-left: variables.$inlineSpacing;
        }

        .p-sortable-column-badge {
            border-radius: 50%;
            height: variables.$tableSortableColumnBadgeSize;
            min-width: variables.$tableSortableColumnBadgeSize;
            line-height: variables.$tableSortableColumnBadgeSize;
            color: variables.$highlightTextColor;
            background: variables.$highlightBg;
            margin-left: variables.$inlineSpacing;
        }

        &:not(.p-highlight):hover {
            background: variables.$tableHeaderCellHoverBg;
            color: variables.$tableHeaderCellTextHoverColor;

            .p-sortable-column-icon {
                color: variables.$tableHeaderCellIconHoverColor;
            }
        }

        &.p-highlight {
            background: variables.$tableHeaderCellHighlightBg;
            color: variables.$tableHeaderCellHighlightTextColor;

            .p-sortable-column-icon {
                color: variables.$tableHeaderCellHighlightTextColor;
            }
        }
    }

    .p-treetable-tbody {
        > tr {
            background: variables.$tableBodyRowBg;
            color: variables.$tableBodyRowTextColor;
            transition: variables.$listItemTransition;

            > td {
                text-align: variables.$tableCellContentAlignment;
                border: variables.$tableBodyCellBorder;
                border-width: variables.$tableBodyCellBorderWidth;
                padding: variables.$tableBodyCellPadding;

                .p-treetable-toggler {
                    @include mixins.action-icon();
                    margin-right: variables.$inlineSpacing;

                    &.p-icon {
                        width: variables.$loadingIconFontSize;
                        height: variables.$loadingIconFontSize;
                    }
                }

                p-treetablecheckbox .p-checkbox {
                    margin-right: variables.$inlineSpacing;
                    
                    .p-indeterminate {
                        .p-checkbox-icon {
                            color: variables.$textColor;
                        }
                    }

                    &.p-variant-filled {
                        .p-checkbox-box {
                            background-color: variables.$inputFilledBg;
                
                            &.p-highlight {
                                background: variables.$checkboxActiveBg;
                            }
                        }
                
                        &:not(.p-disabled) {
                            .p-checkbox-box {
                                &:hover {
                                    background-color: variables.$inputFilledHoverBg;
                                }
                
                                &.p-highlight:hover {
                                    background: variables.$checkboxActiveHoverBg;
                                }
                            }
                        }
                    }
                }
            }

            &:focus-visible {
                outline: 0.15rem solid variables.$focusOutlineColor;
                outline-offset: -0.15rem;
            }

            &.p-highlight {
                background: variables.$highlightBg;
                color: variables.$highlightTextColor;

                .p-treetable-toggler {
                    color: variables.$highlightTextColor;

                    &:hover {
                        color: variables.$highlightTextColor;
                    }
                    
                }
            }
        }
    }

    &.p-treetable-hoverable-rows {
        .p-treetable-tbody > tr:not(.p-highlight):hover {
            background: variables.$tableBodyRowHoverBg;
            color: variables.$tableBodyRowTextHoverColor;

            .p-treetable-toggler {
                color: variables.$tableBodyRowTextHoverColor;
            }
        }
    }

    .p-column-resizer-helper {
        background: variables.$tableResizerHelperBg;
    }

    .p-treetable-scrollable-header,
    .p-treetable-scrollable-footer {
        background: variables.$panelHeaderBg;
    }

    .p-treetable-loading-icon {
        font-size: variables.$loadingIconFontSize;

        &.p-icon {
            width: variables.$loadingIconFontSize;
            height: variables.$loadingIconFontSize;
        }
    }

    &.p-treetable-gridlines {
        .p-datatable-header {
            border-width: 1px 1px 0 1px;
        }

        .p-treetable-footer {
            border-width: 0 1px 1px 1px;
        }

        .p-treetable-top {
            border-width: 0 1px 0 1px;
        }

        .p-treetable-bottom {
            border-width: 0 1px 1px 1px;
        }

        .p-treetable-thead {
            > tr {
                > th {
                    border-width: 1px;
                }
            }
        }

        .p-treetable-tbody {
            > tr {
                > td {
                    border-width: 1px;
                }
            }
        }

        .p-treetable-tfoot {
            > tr {
                > td {
                    border-width: 1px;
                }
            }
        }
    }

    &.p-treetable-sm {
        .p-treetable-header {
            @include mixins.scaledPadding(variables.$tableHeaderPadding, variables.$scaleSM);
        }

        .p-treetable-thead > tr > th {
            @include mixins.scaledPadding(variables.$tableHeaderCellPadding, variables.$tableScaleSM);
        }

        .p-treetable-tbody > tr > td {
            @include mixins.scaledPadding(variables.$tableBodyCellPadding, variables.$tableScaleSM);
        }

        .p-treetable-tfoot > tr > td {
            @include mixins.scaledPadding(variables.$tableFooterPadding, variables.$tableScaleSM);
        }

        .p-treetable-footer {
            @include mixins.scaledPadding(variables.$tableFooterPadding, variables.$tableScaleSM);
        }
    }

    &.p-treetable-lg {
        .p-treetable-header {
            @include mixins.scaledPadding(variables.$tableHeaderPadding, variables.$tableScaleLG);
        }

        .p-treetable-thead > tr > th {
            @include mixins.scaledPadding(variables.$tableHeaderCellPadding, variables.$tableScaleLG);
        }

        .p-treetable-tbody > tr > td {
            @include mixins.scaledPadding(variables.$tableBodyCellPadding, variables.$tableScaleLG);
        }

        .p-treetable-tfoot > tr > td {
            @include mixins.scaledPadding(variables.$tableFooterPadding, variables.$tableScaleLG);
        }

        .p-treetable-footer {
            @include mixins.scaledPadding(variables.$tableFooterPadding, variables.$tableScaleLG);
        }
    }
}
