@use '../../../libs/pm-ui/src/sass/styles.scss';

body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

@media (max-width: 768px) {
  #shark-initiator {
    margin-right: -30px;
  }
}

.iti__country-list {
  z-index: 9999 !important;
}

#bubble-shark {
  content: url('/assets/shark/chat-init.png');
  width: 70px;
  margin-top: 0;
}

.p-paginator-pages {
  @media (max-width: 768px) {
    display: none;
  }
}
