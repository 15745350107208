@use "../../../profitmark-material/variables";

.p-virtualscroller {
    .p-virtualscroller-header {
        background: variables.$tableHeaderBg;
        color: variables.$tableHeaderTextColor;
        border: variables.$tableHeaderBorder;
        border-width: variables.$tableHeaderBorderWidth;
        padding: variables.$tableHeaderPadding;
        font-weight: variables.$tableHeaderFontWeight;
    }

    .p-virtualscroller-content {
        background: variables.$tableBodyRowBg;
        color: variables.$tableBodyRowTextColor;
        border: variables.$dataViewContentBorder;
        padding: variables.$dataViewContentPadding;
    }

    .p-virtualscroller-footer {
        background: variables.$tableFooterBg;
        color: variables.$tableFooterTextColor;
        border: variables.$tableFooterBorder;
        border-width: variables.$tableFooterBorderWidth;
        padding: variables.$tableFooterPadding;
        font-weight: variables.$tableFooterFontWeight;
        border-bottom-left-radius: variables.$borderRadius;
        border-bottom-right-radius: variables.$borderRadius;
    }
}
