@use "sass:list";
@use "../../../profitmark-material/variables";
@use "../../mixins";

.p-orderlist {
	.p-orderlist-controls {
		padding: variables.$panelContentPadding;

		.p-button {
			margin-bottom: variables.$inlineSpacing;
		}
	}
	
	.p-orderlist-list-container {
        background: variables.$panelContentBg;
        border: variables.$panelContentBorder;
        border-radius: variables.$borderRadius;
        transition: variables.$formElementTransition;
        outline-color: transparent;

        &.p-focus {
            @include mixins.focused-input();
        }
    }

	.p-orderlist-header {
		color: variables.$panelHeaderTextColor;
        padding: variables.$panelHeaderPadding;
        font-weight: variables.$panelHeaderFontWeight;
		.p-orderlist-title {
			font-weight: variables.$panelHeaderFontWeight;
		}
	}

	.p-orderlist-filter-container {
		padding: variables.$panelHeaderPadding;
		background: variables.$panelContentBg;
		border: variables.$panelHeaderBorder;
		border-bottom: 0 none;

		.p-orderlist-filter-input {
			padding-right: list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize;
		}

		.p-orderlist-filter-icon {
			right: list.nth(variables.$inputPadding, 2);
			color: variables.$inputIconColor;
		}
	}

	.p-orderlist-list {
		color: variables.$panelContentTextColor;
        padding: variables.$inputListPadding;
		outline: 0 none;

		&:not(:first-child) {
            border-top: variables.$panelContentBorder;
        }
        
		.p-orderlist-item {
			padding: variables.$inputListItemPadding;
			margin: variables.$inputListItemMargin;
			border: variables.$inputListItemBorder;
			color: variables.$inputListItemTextColor;
			background: variables.$inputListItemBg;
			transition: variables.$listItemTransition;

            &:first-child {
                margin-top: 0;
            }
            
			&:not(.p-highlight):hover {
				background: variables.$inputListItemHoverBg;
				color: variables.$inputListItemTextHoverColor;
			}

			&.p-focus {
				color: variables.$inputListItemTextFocusColor;
				background: variables.$inputListItemFocusBg;
			}

			&.p-highlight {
				color: variables.$highlightTextColor;
				background: variables.$highlightBg;

				&.p-focus {
					background: variables.$highlightFocusBg;
				}
			}
		}

		.p-orderlist-empty-message {
			padding: variables.$inputListItemPadding;
			color: variables.$inputListItemTextColor;
		}

		&:not(.cdk-drop-list-dragging) {
			.p-orderlist-item {
				&:not(.p-highlight):hover {
					background: variables.$inputListItemHoverBg;
					color: variables.$inputListItemTextHoverColor;
				}
			}
		}
	}

	&.p-orderlist-striped {
		.p-orderlist-list {
			.p-orderlist-item:nth-child(even) {
				background: variables.$panelContentEvenRowBg;

				&:hover {
					background: variables.$inputListItemHoverBg;
				}
			}
		}
	}
}

.p-orderlist-item {
	&.cdk-drag-preview {
		padding: variables.$inputListItemPadding;
		box-shadow: variables.$inputOverlayShadow;
		border: variables.$inputListItemBorder;
		color: variables.$inputListItemTextColor;
		background: variables.$panelContentBg;
		margin: 0;
	}
}
