@use "sass:list";
@use "../../../profitmark-material/variables";
@use "../../mixins";

.p-dropdown {
    background: variables.$inputBg;
    border: variables.$inputBorder;
    transition: variables.$formElementTransition;
    border-radius: variables.$borderRadius;

    &:not(.p-disabled):hover {
        border-color: variables.$inputHoverBorderColor;
    }

    &:not(.p-disabled).p-focus {
        @include mixins.focused-input();
    }

    &.p-dropdown-clearable {
        .p-dropdown-label {
            padding-right: list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize;
        }
    }

    .p-dropdown-label {
        background: transparent;
        border: 0 none;

        &.p-placeholder {
            color: variables.$inputPlaceholderTextColor;
        }

        &:focus,
        &:enabled:focus {
            outline: 0 none;
            box-shadow: none;
        }
    }

    .p-dropdown-trigger {
        background: transparent;
        color: variables.$inputIconColor;
        width: variables.$inputGroupAddOnMinWidth;
        border-top-right-radius: variables.$borderRadius;
        border-bottom-right-radius: variables.$borderRadius;
    }

    .p-dropdown-clear-icon {
        color: variables.$inputIconColor;
        right: variables.$inputGroupAddOnMinWidth;
    }

    &.p-invalid.p-component {
        @include mixins.invalid-input();
    }

    &.p-variant-filled {
        background-color: variables.$inputFilledBg;

        &:enabled:hover {
            background-color: variables.$inputFilledHoverBg;
        }

        &:enabled:focus {
            background-color: variables.$inputFilledFocusBg;
        }
    }
}

.p-dropdown-panel {
    background: variables.$inputOverlayBg;
    color: variables.$inputListTextColor;
    border: variables.$inputOverlayBorder;
    border-radius: variables.$borderRadius;
    box-shadow: variables.$inputOverlayShadow;

    .p-dropdown-header {
        padding: variables.$inputListHeaderPadding;
        border-bottom: variables.$inputListHeaderBorder;
        color: variables.$inputListHeaderTextColor;
        background: variables.$inputOverlayHeaderBg;
        margin: variables.$inputListHeaderMargin;
        border-top-right-radius: variables.$borderRadius;
        border-top-left-radius: variables.$borderRadius;

        .p-dropdown-filter {
            padding-right: list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize;
            margin-right: -1 * (list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize);
        }

        .p-dropdown-filter-icon {
            right: list.nth(variables.$inputPadding, 2);
            color: variables.$inputIconColor;
        }
    }

    .p-dropdown-items {
        padding: variables.$inputListPadding;

        .p-dropdown-item {
            margin: variables.$inputListItemMargin;
            padding: variables.$inputListItemPadding;
            border: variables.$inputListItemBorder;
            color: variables.$inputListItemTextColor;
            background: variables.$inputListItemBg;
            transition: variables.$listItemTransition;
            border-radius: variables.$inputListItemBorderRadius;

            &:first-child {
                margin-top: 0;
            }

            &.p-highlight {
                color: variables.$highlightTextColor;
                background: variables.$highlightBg;

                &.p-focus {
                    background: variables.$highlightFocusBg;
                }
            }

            &:not(.p-highlight):not(.p-disabled) {
                &.p-focus {
                    color: variables.$inputListItemTextFocusColor;
                    background: variables.$inputListItemFocusBg;
                }

                &:hover {
                    color: variables.$inputListItemTextHoverColor;
                    background: variables.$inputListItemHoverBg;
                }
            }
        }

        .p-dropdown-item-group {
            margin: variables.$submenuHeaderMargin;
            padding: variables.$submenuHeaderPadding;
            color: variables.$submenuHeaderTextColor;
            background: variables.$submenuHeaderBg;
            font-weight: variables.$submenuHeaderFontWeight;
        }

        .p-dropdown-empty-message {
            padding: variables.$inputListItemPadding;
            color: variables.$inputListItemTextColor;
            background: variables.$inputListItemBg;
        }
    }
}

.p-input-filled {
    .p-dropdown {
        background: variables.$inputFilledBg;

        &:not(.p-disabled):hover {
            background-color: variables.$inputFilledHoverBg;
        }
    
        &:not(.p-disabled).p-focus {
            background-color: variables.$inputFilledFocusBg;

            .p-inputtext {
                background-color: transparent;
            }
        }
    }
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
    @include mixins.invalid-input();
}