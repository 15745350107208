@use "../../../profitmark-material/variables";
@use "../../mixins";

.p-slidemenu {
    padding: variables.$verticalMenuPadding;
    background: variables.$menuBg;
    color: variables.$menuTextColor;
    border: variables.$menuBorder;
    border-radius: variables.$borderRadius;
    width: variables.$menuWidth;
    
    .p-slidemenu-root-list {
        outline: 0 none;
    }

    .p-submenu-list {
        outline: 0 none;
    }

    .p-menuitem {
        @include mixins.menuitem();
    }

    &.p-slidemenu-overlay {
        background: variables.$overlayMenuBg;
        border: variables.$overlayMenuBorder;
        box-shadow: variables.$overlayMenuShadow;
    }

    .p-slidemenu-list {
        padding: variables.$verticalMenuPadding;
        background: variables.$overlayMenuBg;
        border: variables.$overlayMenuBorder;
        box-shadow: variables.$overlayMenuShadow;
    }

    .p-menuitem-separator {
        border-top: variables.$divider;
        margin: variables.$menuSeparatorMargin;
    }

    .p-slidemenu-icon {
        font-size: variables.$menuitemSubmenuIconFontSize;
    }

    .p-icon {
        width: variables.$menuitemSubmenuIconFontSize;
        height: variables.$menuitemSubmenuIconFontSize;
    }

    .p-slidemenu-backward {
        padding: variables.$menuitemPadding;
	    color: variables.$menuitemTextColor;
        &:not(.p-disabled):focus {
            @include mixins.focused-inset();
        }
    }

    .p-menuitem-badge {
        @include mixins.menuitem-badge();
    }
}
