@use "sass:meta";
@use "../../../profitmark-material/variables";
@use "../../mixins";

.p-toast {
    opacity: variables.$toastOpacity;

    .p-toast-message {
        margin: variables.$toastMargin;
        box-shadow: variables.$toastShadow;
        border-radius: variables.$borderRadius;

        .p-toast-message-content {
            padding: variables.$toastPadding;
            border-width: variables.$toastBorderWidth;

            .p-toast-message-text {
                margin: variables.$toastMessageTextMargin;
            }

            .p-toast-message-icon {
                font-size: variables.$toastIconFontSize;
            }

            .p-icon:not(.p-toast-icon-close-icon) {
                width: variables.$toastIconFontSize;
                height: variables.$toastIconFontSize;
            }

            .p-toast-summary {
                font-weight: variables.$toastTitleFontWeight;
            }

            .p-toast-detail {
                margin: variables.$toastDetailMargin;
            }
        }

        .p-toast-icon-close {
            width: variables.$toastIconFontSize;
            height: variables.$toastIconFontSize;
            border-radius: variables.$actionIconBorderRadius;
            background: transparent;
            transition: variables.$actionIconTransition;

            &:hover {
                background: rgba(255,255,255,.5);
            }

            &:focus-visible {
                @include mixins.focused();
            }
        }

        &.p-toast-message-info {
            background: variables.$infoMessageBg;
            border: variables.$infoMessageBorder;
            border-width: variables.$messageBorderWidth;
            color: variables.$infoMessageTextColor;

            .p-toast-message-icon,
            .p-toast-icon-close {
                color: variables.$infoMessageIconColor;
            }
        }

        &.p-toast-message-success {
            background: variables.$successMessageBg;
            border: variables.$successMessageBorder;
            border-width: variables.$messageBorderWidth;
            color: variables.$successMessageTextColor;

            .p-toast-message-icon,
            .p-toast-icon-close {
                color: variables.$successMessageIconColor;
            }
        }

        &.p-toast-message-warn {
            background: variables.$warningMessageBg;
            border: variables.$warningMessageBorder;
            border-width: variables.$messageBorderWidth;
            color: variables.$warningMessageTextColor;

            .p-toast-message-icon,
            .p-toast-icon-close {
                color: variables.$warningMessageIconColor;
            }
        }

        &.p-toast-message-error {
            background: variables.$errorMessageBg;
            border: variables.$errorMessageBorder;
            border-width: variables.$messageBorderWidth;
            color: variables.$errorMessageTextColor;

            .p-toast-message-icon,
            .p-toast-icon-close {
                color: variables.$errorMessageIconColor;
            }
        }

        @if meta.variable-exists(secondaryMessageBg) {
            &.p-toast-message-secondary {
                background: variables.$secondaryMessageBg;
                border: variables.$secondaryMessageBorder;
                border-width: variables.$messageBorderWidth;
                color: variables.$secondaryMessageTextColor;

                .p-toast-message-icon,
                .p-toast-icon-close {
                    color: variables.$secondaryMessageIconColor;
                }
            }
        }
        
        @if meta.variable-exists(contrastMessageBg) {
            &.p-toast-message-contrast {
                background: variables.$contrastMessageBg;
                border: variables.$contrastMessageBorder;
                border-width: variables.$messageBorderWidth;
                color: variables.$contrastMessageTextColor;

                .p-toast-message-icon,
                .p-toast-icon-close {
                    color: variables.$contrastMessageIconColor;
                }
            }
        }
    }
}
