@use "../profitmark-material/variables";

.p-splitter {
    .p-splitter-gutter {
        .p-splitter-gutter-handle {
            transition: transform variables.$transitionDuration, box-shadow variables.$transitionDuration;
            transform: scale(.7);
    
            &:focus {
                box-shadow: 0 0 1px 6px rgba(variables.$accentColor, .2);
            }
        }
    
        &.p-splitter-gutter-resizing {
            .p-splitter-gutter-handle {
                transform: scale(1);
            }
        }
    }
}