@use "mixins";

.p-input-filled {
    .p-inputgroup {
        .p-inputgroup-addon {
            @include mixins.filled-input-default();

            &:last-child {
                border-right-color: transparent;
            }
        }
    }

    .p-inputgroup-addon:first-child,
    .p-inputgroup button:first-child,
    .p-inputgroup input:first-child {
        border-bottom-left-radius: 0;
    }

    .p-inputgroup .p-float-label:first-child input {
        border-bottom-left-radius: 0;
    }

    .p-inputgroup-addon:last-child,
    .p-inputgroup button:last-child,
    .p-inputgroup input:last-child  {
        border-bottom-right-radius: 0;
    }

    .p-inputgroup .p-float-label:last-child input {
        border-bottom-right-radius: 0;
    }
}

