@use "../../../profitmark-material/variables";
@use "../../mixins";

.p-radiobutton {
    width: variables.$radiobuttonWidth;
    height: variables.$radiobuttonHeight;

    .p-radiobutton-box {
        border: variables.$radiobuttonBorder;
        background: variables.$inputBg;
        width: variables.$radiobuttonWidth;
        height: variables.$radiobuttonHeight;
        color: variables.$textColor;
        border-radius: 50%;
        transition: variables.$formElementTransition;
        outline-color: transparent;

        &:not(.p-disabled):not(.p-highlight):hover {
            border-color: variables.$inputHoverBorderColor;
        }

        &:not(.p-disabled).p-focus {
            @include mixins.focused-input();
        }

        .p-radiobutton-icon {
            width: variables.$radiobuttonIconSize;
            height: variables.$radiobuttonIconSize;
            transition-duration: variables.$transitionDuration;
            background-color: variables.$radiobuttonIconActiveColor;
        }

        &.p-highlight {
            border-color: variables.$radiobuttonActiveBorderColor;
            background: variables.$radiobuttonActiveBg;

            &:not(.p-disabled):hover {
                border-color: variables.$radiobuttonActiveHoverBorderColor;
                background: variables.$radiobuttonActiveHoverBg;
                color: variables.$radiobuttonIconActiveHoverColor;
            }
        }
    }

    &.p-variant-filled {
        .p-radiobutton-box {
            background-color: variables.$inputFilledBg;

            &:not(.p-disabled):hover {
                background-color: variables.$inputFilledHoverBg;
            }

            &.p-highlight {
                background: variables.$radiobuttonActiveBg;

                &:not(.p-disabled):hover {
                    background: variables.$radiobuttonActiveHoverBg;
                }
            }
        }
    }
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    @include mixins.invalid-input();
}

.p-input-filled {
    .p-radiobutton {
        .p-radiobutton-box {
            background-color: variables.$inputFilledBg;

            &:not(.p-disabled):hover {
                background-color: variables.$inputFilledHoverBg;
            }

            &.p-highlight {
                background: variables.$radiobuttonActiveBg;

                &:not(.p-disabled):hover {
                    background: variables.$radiobuttonActiveHoverBg;
                }
            }
        }
    }
}

.p-radiobutton-label {
    margin-left: variables.$inlineSpacing;
}

@if (variables.$highlightBg == variables.$radiobuttonActiveBg) {
    .p-highlight {
        .p-radiobutton {
            .p-radiobutton-box {
                border-color: variables.$radiobuttonIconActiveColor;
            }
        }
    }
}
