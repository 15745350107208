@use "../../../profitmark-material/variables";
@use "../../mixins";

.p-accordion {
    .p-accordion-header {
        .p-accordion-header-link {
            padding: variables.$accordionHeaderPadding;
            border: variables.$accordionHeaderBorder;
            color: variables.$accordionHeaderTextColor;
            background: variables.$accordionHeaderBg;
            font-weight: variables.$accordionHeaderFontWeight;
            border-radius: variables.$borderRadius;
            transition: variables.$listItemTransition;

            .p-accordion-toggle-icon {
                margin-right: variables.$inlineSpacing;
            }
        }

        &:not(.p-disabled) {
            .p-accordion-header-link {
                &:focus-visible {
                    @include mixins.focused-inset();
                }
            }
        }

        &:not(.p-highlight):not(.p-disabled):hover {
            .p-accordion-header-link {
                background: variables.$accordionHeaderHoverBg;
                border-color: variables.$accordionHeaderHoverBorderColor;
                color: variables.$accordionHeaderTextHoverColor;
            }
        }

        &:not(.p-disabled).p-highlight {
            .p-accordion-header-link {
                background: variables.$accordionHeaderActiveBg;
                border-color: variables.$accordionHeaderActiveBorderColor;
                color: variables.$accordionHeaderTextActiveColor;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:hover {
                .p-accordion-header-link {
                    border-color: variables.$accordionHeaderActiveHoverBorderColor;
                    background: variables.$accordionHeaderActiveHoverBg;
                    color: variables.$accordionHeaderTextActiveHoverColor;
                }
            }
        }
    }

    .p-accordion-content {
        padding: variables.$accordionContentPadding;
        border: variables.$accordionContentBorder;
        background: variables.$accordionContentBg;
        color: variables.$accordionContentTextColor;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: variables.$borderRadius;
        border-bottom-left-radius: variables.$borderRadius;
    }

    p-accordiontab {
        .p-accordion-tab {
            margin-bottom: variables.$accordionSpacing;
        }
        
        @if variables.$accordionSpacing == 0 {
            .p-accordion-header {
                .p-accordion-header-link {
                    border-radius: 0;
                }
            }

            .p-accordion-content {
                border-radius: 0;
            }

            &:not(:first-child) {
                .p-accordion-header {
                    .p-accordion-header-link {
                        border-top: 0 none;
                    }

                    &:not(.p-highlight):not(.p-disabled):hover,
                    &:not(.p-disabled).p-highlight:hover {
                        .p-accordion-header-link {
                            border-top: 0 none;
                        }
                    }
                }
            }

            &:first-child {
                .p-accordion-header {
                    .p-accordion-header-link {
                        border-top-right-radius: variables.$borderRadius;
                        border-top-left-radius: variables.$borderRadius;
                    }
                }
            }

            &:last-child {
                .p-accordion-header:not(.p-highlight) {
                    .p-accordion-header-link {
                        border-bottom-right-radius: variables.$borderRadius;
                        border-bottom-left-radius: variables.$borderRadius;
                    }
                }

                .p-accordion-content {
                    border-bottom-right-radius: variables.$borderRadius;
                    border-bottom-left-radius: variables.$borderRadius;
                }
            }
        } 
    }
}
