@use "../../../profitmark-material/variables";

@use "sass:math";

// core
.p-metergroup {
    display: flex;
}

.p-metergroup-meters {
    display: flex;
}

.p-metergroup-vertical .p-metergroup-meters {
    flex-direction: column;
}

.p-metergroup-labels {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.p-metergroup-vertical .p-metergroup-labels {
    align-items: start;
}

.p-metergroup-labels-vertical {
    flex-direction: column;
}

.p-metergroup-label {
    display: inline-flex;
    align-items: center;
}

.p-metergroup-label-marker {
    display: inline-flex;
}

// theme
.p-metergroup {
    gap: variables.$inlineSpacing * 2;

    .p-metergroup-meters {
        background: variables.$progressBarBg;
        border-radius: variables.$borderRadius;
    }

    .p-metergroup-meter {
        border: variables.$progressBarBorder;
        background: variables.$progressBarValueBg;
    }

    .p-metergroup-labels {
        .p-metergroup-label {
            gap: variables.$inlineSpacing;
        }

        .p-metergroup-label-marker {
            background: variables.$progressBarValueBg;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 100%;
        }

        .p-metergroup-label-icon {
            width: 1rem;
            height: 1rem;
        }

        &.p-metergroup-labels-vertical {
            gap: variables.$inlineSpacing;
        }

        &.p-metergroup-labels-horizontal {
            gap: variables.$inlineSpacing * 2;
        }
    }

    &.p-metergroup-horizontal {
        flex-direction: column;

        .p-metergroup-meters {
            height: 0.5rem;
        }

        .p-metergroup-meter:first-of-type {
            border-top-left-radius: variables.$borderRadius;
            border-bottom-left-radius: variables.$borderRadius;
        }

        .p-metergroup-meter:last-of-type {
            border-top-right-radius: variables.$borderRadius;
            border-bottom-right-radius: variables.$borderRadius;
        }
    }

    &.p-metergroup-vertical {
        flex-direction: row;

        .p-metergroup-meters {
            width: 0.5rem;
            height: 100%;
        }

        .p-metergroup-meter:first-of-type {
            border-top-left-radius: variables.$borderRadius;
            border-top-right-radius: variables.$borderRadius;
        }

        .p-metergroup-meter:last-of-type {
            border-bottom-left-radius: variables.$borderRadius;
            border-bottom-right-radius: variables.$borderRadius;
        }
    }
}
