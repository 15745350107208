@use "../profitmark-material/variables";

.p-slider {
    .p-slider-handle {
        transition: transform variables.$transitionDuration, box-shadow variables.$transitionDuration;
        transform: scale(.7);

        &:focus {
            box-shadow: 0 0 1px 10px rgba(variables.$accentColor, .2);
        }
    }

    &.p-slider-sliding {
        .p-slider-handle {
            transform: scale(1);
        }
    }
}