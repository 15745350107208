@use "sass:list";
@use "../../../profitmark-material/variables";

.p-icon-field-left{
    .p-input-icon:first-of-type {
        left: list.nth(variables.$inputPadding, 2);
        color: variables.$inputIconColor;
    }
}

.p-icon-field-right {
    .p-input-icon:last-of-type  {
        right: list.nth(variables.$inputPadding, 2);
        color: variables.$inputIconColor;
    }
}