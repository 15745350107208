@use "sass:meta";
@use "../../../profitmark-material/variables";
@use "../../mixins";

@use "sass:math";

//core
.p-stepper .p-stepper-nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-x: auto;
}

.p-stepper-vertical .p-stepper-nav {
    flex-direction: column;
}

.p-stepper-header {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    align-items: center;

    &:last-of-type {
        flex: initial;
    }
}

.p-stepper-header .p-stepper-action {
    border: 0 none;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    &:focus-visible {
        @include mixins.focused();
    }
}

.p-stepper.p-stepper-readonly .p-stepper-header {
    cursor: auto;
}

.p-stepper-header.p-highlight .p-stepper-action {
    cursor: default;
}

.p-stepper-title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.p-stepper-number {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-stepper-separator {
    flex: 1 1 0;
}

//theme
.p-stepper {
    .p-stepper-nav {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .p-stepper-header {
        padding: variables.$inlineSpacing;

        .p-stepper-action {
            transition: variables.$listItemTransition;
            border-radius: variables.$borderRadius;
            background: variables.$stepsItemBg;
            outline-color: transparent;

            .p-stepper-number {
                color: variables.$stepsItemNumberColor;
                border: variables.$stepsItemBorder;
                border-width: 2px;
                background: variables.$stepsItemBg;
                min-width: variables.$stepsItemNumberWidth;
                height: variables.$stepsItemNumberHeight;
                line-height: variables.$stepsItemNumberHeight;
                font-size: variables.$stepsItemNumberFontSize;
                border-radius: variables.$stepsItemNumberBorderRadius;
                transition: variables.$actionIconTransition;
            }

            .p-stepper-title {
                margin-left: variables.$inlineSpacing;
                color: variables.$stepsItemTextColor;
                font-weight: variables.$stepsItemActiveFontWeight;
                transition: variables.$actionIconTransition;
            }

            &:not(.p-disabled):focus-visible {
                @include mixins.focused();
            }
        }

        &.p-highlight {
            .p-stepper-number {
                background: variables.$highlightBg;
                color: variables.$highlightTextColor;
            }

            .p-stepper-title {
                color: variables.$textColor;
            }
        }

        &:not(.p-disabled):focus-visible {
            @include mixins.focused();
        }

        &:has(~ .p-highlight) {
            @if meta.variable-exists(primaryColor) {
                .p-stepper-separator {
                    background-color: variables.$primaryColor;
                }
            }
        }
    }

    .p-stepper-panels {
        background: variables.$tabviewContentBg;
        padding: variables.$tabviewContentPadding;
        color: variables.$tabviewContentTextColor;
    }

    .p-stepper-separator {
        background-color: variables.$timelineEventColor;
        width: 100%;
        height: 2px;
        margin-inline-start: calc(variables.$inlineSpacing * 2);
        transition: variables.$listItemTransition;
    }

    &.p-stepper-vertical {
        display: flex;
        flex-direction: column;

        .p-stepper-toggleable-content {
            display: flex;
            flex: 1 1 auto;
            background: variables.$tabviewContentBg;
            color: variables.$tabviewContentTextColor;
        }

        .p-stepper-panel {
            display: flex;
            flex-direction: column;
            flex: initial;

            &.p-stepper-panel-active {
                flex: 1 1 auto;
            }

            .p-stepper-header {
                flex: initial;
            }

            .p-stepper-content {
                width: 100%;
                padding-left: calc(variables.$inlineSpacing * 2);
            }

            .p-stepper-separator {
                flex: 0 0 auto;
                width: 2px;
                height: auto;
                margin-inline-start: calc(variables.$inlineSpacing * 7 / 2 + 2px);
            }

            &:has(~ .p-stepper-panel-active) {
                @if meta.variable-exists(primaryColor) {
                    .p-stepper-separator {
                        background-color: variables.$primaryColor;
                    }
                }
            }

            &:last-of-type {
                .p-stepper-content {
                    padding-left: calc(variables.$inlineSpacing * 6);
                }
            }
        }
    }
}