@use "../../../profitmark-material/variables";

.p-timeline {
    .p-timeline-event-marker {
        border: variables.$timelineEventMarkerBorder; 
        border-radius: variables.$timelineEventMarkerBorderRadius;
        width: variables.$timelineEventMarkerWidth;
        height: variables.$timelineEventMarkerHeight;
        background-color: variables.$timelineEventMarkerBackground;
    }

    .p-timeline-event-connector {
        background-color: variables.$timelineEventColor;
    }

    &.p-timeline-vertical {
        .p-timeline-event-opposite,
        .p-timeline-event-content  {
            padding: variables.$timelineVerticalEventContentPadding;
        }

        .p-timeline-event-connector {
            width: variables.$timelineEventConnectorSize;
        }
    }

    &.p-timeline-horizontal {
        .p-timeline-event-opposite,
        .p-timeline-event-content  {
            padding: variables.$timelineHorizontalEventContentPadding;
        }

        .p-timeline-event-connector {
            height: variables.$timelineEventConnectorSize;
        }
    }
}



