@use "../../../profitmark-material/variables";
@use "../../mixins";

.p-scrollpanel {
    .p-scrollpanel-bar {
        background: variables.$scrollPanelTrackBg;
        border: variables.$scrollPanelTrackBorder;
        transition: variables.$formElementTransition;

        &:focus-visible {
            @include mixins.focused();
        }
    }
}