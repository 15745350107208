@use "../../../profitmark-material/variables";

.p-terminal {
	background: variables.$panelContentBg;
	color: variables.$panelContentTextColor;
	border: variables.$panelContentBorder;
	padding: variables.$panelContentPadding;

	.p-terminal-input {
        font-family: var(--font-family);
		font-feature-settings: var(--font-feature-settings, normal);
		font-size: variables.$inputTextFontSize;
	}
}
