@use "sass:list";
@use "../../../profitmark-material/variables";
@use "../../mixins";

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
    @include mixins.invalid-input();
}

.p-password-panel {
    padding: variables.$panelContentPadding;
    background: variables.$panelContentBg;
    color: variables.$panelContentTextColor;
    border: variables.$overlayContentBorder;
    box-shadow: variables.$inputOverlayShadow;
    border-radius: variables.$borderRadius;

    .p-password-meter {
        margin-bottom: variables.$inlineSpacing;
        background: variables.$passwordMeterBg;

        .p-password-strength {
            &.weak {
                background: variables.$passwordWeakBg;
            }

            &.medium {
                background: variables.$passwordMediumBg;
            }

            &.strong {
                background: variables.$passwordStrongBg;
            }
        }
    }
}

p-password.p-password-clearable {
    .p-password-input {
        padding-right: list.nth(variables.$inputPadding, 2) * 2 + variables.$primeIconFontSize;
    }
    .p-password-clear-icon {
        color: variables.$inputIconColor;
        right: list.nth(variables.$inputPadding, 2);
    }
}

p-password.p-password-clearable.p-password-mask{
    .p-password-input {
        padding-right: list.nth(variables.$inputPadding, 2) * 3 + variables.$primeIconFontSize * 2;
    }

    .p-password-clear-icon {
        color: variables.$inputIconColor;
        right: list.nth(variables.$inputPadding, 2) * 2 + variables.$primeIconFontSize;
    }
}